import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'web_component_library/flex';
import Text from 'web_component_library/text';
import { Box } from 'rebass/styled-components';

const RateBlock = ({ rateType, rate, pt = 0, pb = 0, renderAsRow = false }) => (
  <Flex pt={pt} pb={pb} flexDirection={renderAsRow ? 'row' : 'column'}>
    <Box width={1}>
      <Text lineHeight={[0, 5]} fontWeight={1} color="freedomCharcoal">
        {rateType}
      </Text>
    </Box>
    <Flex justifyContent={renderAsRow ? 'end' : 'start'} width={1}>
      <Text lineHeight={[0, 5]} fontWeight={2} color="black" te>
        {rate}
      </Text>
    </Flex>
  </Flex>
);

RateBlock.propTypes = {
  rateType: PropTypes.string.isRequired,
  rate: PropTypes.string.isRequired,
  pt: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  pb: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  renderAsRow: PropTypes.bool,
};

export default RateBlock;
