import ContentfulModel from './contentfulModel';
import Tag from './tag';
import { PT_PREPAID } from '../../constants';

export default class AddOn extends ContentfulModel {
  constructor(data) {
    super(data);
    if (data && data.fields && data.sys) {
      const {
        sku,
        name,
        category,
        tags,
        description,
        descriptionRichText,
        descriptionPriceText,
        price,
        priceUnit,
        ctaLearnMore,
        imageForTooltip,
        tooltipContent,
        rank,
        hideFromBrowse,
        countryList,
      } = data.fields;
      this.id = data.sys.id;
      this.sku = sku;
      this.name = name;
      this.category = category;
      this.tags = this.extractEntriesToList(tags, Tag);
      this.isPrepaid = this.tags.some(
        tag => tag.tag.toLowerCase() === PT_PREPAID,
      );
      this.description = description;
      this.descriptionRichText = descriptionRichText;
      this.price = price;
      this.priceUnit = priceUnit;
      this.ctaLearnMore = ctaLearnMore;
      this.imageForTooltip = imageForTooltip;
      this.tooltipContent = tooltipContent;
      this.rank = rank || 0;
      this.hideFromBrowse = hideFromBrowse || true;
      this.countryList = this.extractCountries(countryList);
      this.descriptionPriceText = descriptionPriceText;
    }
  }

  extractCountries = countryList => {
    if (!countryList) return null;
    return countryList.map(country => country.fields.name);
  };
}
