import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import Divider from 'web_component_library/divider';
import freedomTheme from 'web_component_library/theme/freedom';
import { Box } from 'rebass/styled-components';
import RoamingRatesWithAddOns from './roamingRatesWithAddOns';
import RoamingRates from './roamingRates';
import CallingRates from './callingRates';
import CallingRatesWithAddOns from './callingRatesWithAddOns';
import {
  selectAddOns,
  selectLocale,
  selectPopupModals,
} from '../../containers/contentfulHoc/selectors';
import { getAddOns } from '../../containers/contentfulHoc/actions';
import { ModalContext } from '../modal/modalContext';
import Modal from '../modalComponent';
import {
  ANALYTICS_BTN_PROPS,
  CALLING_RATES,
  CALLING_RATES_WITH_ADDONS,
  ROAMING_RATES,
  ROAMING_RATES_WITH_ADDONS,
} from '../../constants';
import { setUtagForLinks } from '../../lib/analytics';
import AddOn from '../../containers/contentful/addOn';

const { colors } = freedomTheme;

export class RatesFilter extends PureComponent {
  static propTypes = {
    leftBlock: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired,
    rightBlock: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired,
    buttonContainer: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired,
    ratesPlanDisclaimer: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.object).isRequired,
    }),
    title: PropTypes.string.isRequired,
    linkText: PropTypes.string,
    divider: PropTypes.bool.isRequired,
    variant: PropTypes.string,
    additionalData: PropTypes.object,
    callingRatesText: PropTypes.string,
    standardRateText: PropTypes.string,
    worldSaverRateText: PropTypes.string,
    roamingRatesText: PropTypes.string,
    standardRatesText: PropTypes.string,
    worldTravellerRatesText: PropTypes.string,
    dataText: PropTypes.string,
    talkText: PropTypes.string,
    textText: PropTypes.string,
    defaultCountry: PropTypes.string,
    popupModals: PropTypes.object.isRequired,
    filterType: PropTypes.oneOf([
      CALLING_RATES,
      CALLING_RATES_WITH_ADDONS,
      ROAMING_RATES,
      ROAMING_RATES_WITH_ADDONS,
    ]).isRequired,
    addOns: PropTypes.arrayOf(PropTypes.instanceOf(AddOn)).isRequired,
    commonSearchesTitle: PropTypes.string,
    commonSearches: PropTypes.arrayOf(PropTypes.object),
    locale: PropTypes.string.isRequired,
    onGetAddOns: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    errorMessage: PropTypes.string.isRequired,
    defaultErrorMessage: PropTypes.string.isRequired,
  };

  static defaultProps = {
    variant: 'light',
    additionalData: {},
    defaultCountry: null,
    callingRatesText: '',
    standardRateText: '',
    worldSaverRateText: '',
    roamingRatesText: '',
    standardRatesText: '',
    worldTravellerRatesText: '',
    dataText: '',
    talkText: '',
    textText: '',
    linkText: '',
    ratesPlanDisclaimer: null,
    commonSearchesTitle: null,
    commonSearches: [],
  };

  static contextType = ModalContext;

  constructor(props) {
    super(props);

    this.state = {
      defaultCountry: props.defaultCountry,
      selected: null,
      errorMessage: '',
      addOnsCountry: [],
    };
  }

  isRoamingRates = () =>
    this.props.filterType === ROAMING_RATES ||
    this.props.filterType === ROAMING_RATES_WITH_ADDONS;

  openPopup = () => {
    const { popupModals } = this.props;
    const type = this.isRoamingRates()
      ? 'allCountriesRoamingRates'
      : 'allCountriesCallingRates';

    this.context.showModal(Modal, popupModals[type]);
  };

  validateField = value => {
    if (!value.length) {
      this.clearForm();
    }
  };

  clearForm = () => {
    this.setState({
      defaultCountry: null,
      selected: null,
      errorMessage: '',
      addOnsCountry: [],
    });
  };

  // Filter the addOns accordingly with selected country
  getAddOnsCountry = country => {
    if (!this.props.addOns || !country) return [];
    return this.props.addOns
      .filter(addon =>
        addon.countryList ? addon.countryList.includes(country) : null,
      )
      .filter(Boolean);
  };

  updateDefaultCountry() {
    const getAddOnsCountry = this.getAddOnsCountry(this.props.defaultCountry);
    this.setState({
      selected: null,
      errorMessage: '',
      addOnsCountry: [...getAddOnsCountry],
    });
  }

  // If the default country has any addOns linked
  // it must be show up on first render
  componentDidUpdate(prevProps) {
    const { addOns, defaultCountry } = this.props;
    if (prevProps.addOns !== addOns && defaultCountry) {
      this.updateDefaultCountry();
    }
  }

  // Load the addOns
  componentDidMount() {
    const { onGetAddOns, locale } = this.props;
    onGetAddOns(locale);
  }

  search = value => {
    const { errorMessage, defaultErrorMessage } = this.props;

    if (!value.length) {
      this.setState({
        errorMessage: defaultErrorMessage,
        defaultCountry: null,
        selected: null,
        addOnsCountry: [],
      });
      return;
    }

    const country = this.isExists(value);
    const processedValue = country ? country.fields.name : '';

    const generatedErrorMessage = `${errorMessage} "${value}"`;
    const generatedEvName = this.isRoamingRates()
      ? 'travel and roaming'
      : 'international calling';
    const generatedEvData = `search success - ${processedValue}`;
    if (!this.isExists(value)) {
      this.setState({
        defaultCountry: null,
        selected: null,
        errorMessage: generatedErrorMessage,
        addOnsCountry: [],
      });
      setUtagForLinks({
        ev_type: 'interaction',
        ev_action: 'search',
        ev_name: generatedEvName,
        ev_data: 'no results',
      });
      return;
    }

    const getAddOnsCountry = this.getAddOnsCountry(processedValue);

    this.setState({
      selected: processedValue,
      errorMessage: '',
      addOnsCountry: getAddOnsCountry,
    });
    setUtagForLinks({
      ev_type: 'interaction',
      ev_action: 'search',
      ev_name: generatedEvName,
      ev_data: generatedEvData,
    });
  };

  isExists = value => {
    const {
      additionalData: {
        fields: { countries },
      },
    } = this.props;
    return countries.find(
      item => item.fields.name.toLowerCase() === value.toLowerCase(),
    );
  };

  render() {
    const {
      leftBlock,
      rightBlock,
      title,
      linkText,
      divider,
      variant,
      callingRatesText,
      standardRateText,
      worldSaverRateText,
      roamingRatesText,
      standardRatesText,
      worldTravellerRatesText,
      dataText,
      talkText,
      textText,
      ratesPlanDisclaimer,
      commonSearchesTitle,
      commonSearches,
      label,
      additionalData: {
        fields: { countries },
      },
      filterType,
    } = this.props;

    const isRoamingRates = this.isRoamingRates();

    const analyticsSeeAllCountries = {
      ...ANALYTICS_BTN_PROPS,
      'data-ev_type': 'interaction',
      'data-ev_action': 'click',
      'data-ev_name': 'see all countries',
    };
    const {
      selected,
      defaultCountry,
      errorMessage,
      addOnsCountry,
    } = this.state;

    const list = countries.map(item => item.fields.name);

    const getCountry = selected || defaultCountry;

    const selectedCountry = getCountry ? this.isExists(getCountry) : {};

    return (
      <Box width={1}>
        {divider && !isRoamingRates && (
          <Box>
            <Divider thickness={2} color={colors.black} />
          </Box>
        )}
        {filterType === CALLING_RATES && (
          <CallingRates
            country={getCountry}
            title={title}
            leftBlock={leftBlock}
            variant={variant}
            validateField={this.validateField}
            clearForm={this.clearForm}
            label={label}
            list={list}
            errorMessage={errorMessage}
            search={this.search}
            rightBlock={rightBlock}
            openPopup={this.openPopup}
            analyticsSeeAllCountries={analyticsSeeAllCountries}
            linkText={linkText}
            callingRatesText={callingRatesText}
            standardRateText={standardRateText}
            worldSaverRateText={worldSaverRateText}
            ratesPlanDisclaimer={ratesPlanDisclaimer}
            selectedCountry={selectedCountry}
          />
        )}
        {filterType === CALLING_RATES_WITH_ADDONS && (
          <CallingRatesWithAddOns
            country={getCountry}
            title={title}
            leftBlock={leftBlock}
            variant={variant}
            validateField={this.validateField}
            clearForm={this.clearForm}
            label={label}
            list={list}
            errorMessage={errorMessage}
            search={this.search}
            rightBlock={rightBlock}
            commonSearchesTitle={commonSearchesTitle}
            commonSearches={commonSearches}
            openPopup={this.openPopup}
            analyticsSeeAllCountries={analyticsSeeAllCountries}
            linkText={linkText}
            callingRatesText={callingRatesText}
            standardRateText={standardRateText}
            worldSaverRateText={worldSaverRateText}
            ratesPlanDisclaimer={ratesPlanDisclaimer}
            selectedCountry={selectedCountry}
            addOnsCountry={addOnsCountry}
          />
        )}
        {filterType === ROAMING_RATES && (
          <RoamingRates
            country={getCountry}
            title={title}
            leftBlock={leftBlock}
            variant={variant}
            validateField={this.validateField}
            clearForm={this.clearForm}
            label={label}
            list={list}
            errorMessage={errorMessage}
            search={this.search}
            rightBlock={rightBlock}
            openPopup={this.openPopup}
            analyticsSeeAllCountries={analyticsSeeAllCountries}
            linkText={linkText}
            roamingRatesText={roamingRatesText}
            standardRatesText={standardRatesText}
            worldTravellerRatesText={worldTravellerRatesText}
            dataText={dataText}
            talkText={talkText}
            textText={textText}
            ratesPlanDisclaimer={ratesPlanDisclaimer}
            selectedCountry={selectedCountry}
          />
        )}
        {filterType === ROAMING_RATES_WITH_ADDONS && (
          <RoamingRatesWithAddOns
            country={getCountry}
            title={title}
            leftBlock={leftBlock}
            variant={variant}
            validateField={this.validateField}
            clearForm={this.clearForm}
            label={label}
            list={list}
            errorMessage={errorMessage}
            search={this.search}
            rightBlock={rightBlock}
            commonSearchesTitle={commonSearchesTitle}
            commonSearches={commonSearches}
            openPopup={this.openPopup}
            analyticsSeeAllCountries={analyticsSeeAllCountries}
            linkText={linkText}
            roamingRatesText={roamingRatesText}
            standardRatesText={standardRatesText}
            worldTravellerRatesText={worldTravellerRatesText}
            dataText={dataText}
            talkText={talkText}
            textText={textText}
            ratesPlanDisclaimer={ratesPlanDisclaimer}
            selectedCountry={selectedCountry}
            addOnsCountry={addOnsCountry}
          />
        )}
        {divider && isRoamingRates && (
          <Box>
            <Divider thickness={2} color={colors.black} />
          </Box>
        )}
      </Box>
    );
  }
}

export const mapStateToProps = createStructuredSelector({
  popupModals: selectPopupModals(),
  addOns: selectAddOns(),
  locale: selectLocale(),
});

const mapDispatchToProps = dispatch => ({
  onGetAddOns: payload => dispatch(getAddOns(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RatesFilter);
