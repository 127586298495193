import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import Divider from 'web_component_library/divider';
import Input from 'web_component_library/input';
import TextBlock from 'web_component_library/textBlock';
import Text from 'web_component_library/text';
import freedomTheme from 'web_component_library/theme/freedom';
import WclButton from 'web_component_library/button';
import { Flex, Box, Heading } from 'rebass/styled-components';
import { Button } from './css';
import RestrictContent from '../restrictContent';
import { AddonCards } from '../addonCards';
import RateBlock from '../ratesBlock';

const defaultCountry = '';
const defaultVariant = 'light';
const defaultCommonSearches = [];
const defaultLinkText = '';
const defaultSelectedCountry = {};
const defaultAddOnsCountry = [];

const RoamingRatesWithAddOns = ({
  country = defaultCountry,
  title,
  leftBlock,
  variant = defaultVariant,
  validateField,
  clearForm,
  label,
  list,
  errorMessage = null,
  search,
  rightBlock,
  commonSearchesTitle = null,
  commonSearches = defaultCommonSearches,
  openPopup,
  analyticsSeeAllCountries,
  linkText = defaultLinkText,
  roamingRatesText,
  standardRatesText,
  worldTravellerRatesText,
  dataText,
  talkText,
  textText,
  ratesPlanDisclaimer = null,
  selectedCountry = defaultSelectedCountry,
  addOnsCountry = defaultAddOnsCountry,
}) => {
  const {
    fields: {
      name,
      roamingRatesDescription,
      standardDataRate,
      standardTalkRate,
      standardTextRate,
      worldSaverDataRate,
      worldSaverTalkRate,
      worldSaverTextRate,
    } = {},
  } = selectedCountry;
  const { colors } = freedomTheme;

  return (
    <>
      <RestrictContent>
        <Flex
          width={1}
          pt={[11, 21]}
          pb={[9, 21]}
          px={[9, 0]}
          flexDirection="column"
          alignItems="center"
        >
          <Box width={1} pb={7} textAlign={['left', 'center']}>
            <Heading fontSize={[7, 9]} lineHeight={[5, 7]}>
              {title}
            </Heading>
          </Box>
          <Box width={[1, 1 / 2]} pb={[5, 17]} textAlign={['left', 'center']}>
            <TextBlock data={leftBlock} blockVariant={variant} />
          </Box>
          <Flex width={1} pb={5} flexDirection="column" alignItems="center">
            <Formik
              initialValues={{
                country: '',
              }}
              novalidate
              validate={values => validateField(values.country)}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                search(values.country);
              }}
              render={({ values, handleChange, setFieldValue }) => (
                <>
                  <Box data-testId="roamingRatesForm" width={[1, 1 / 2]}>
                    <Form>
                      <Input
                        id="country"
                        name="country"
                        type="text"
                        label={label}
                        list={list}
                        value={values.country}
                        onChange={handleChange}
                        clearForm={clearForm}
                        errorMessage={errorMessage}
                        showInline
                        setFieldValue={setFieldValue}
                        search={search}
                        isSearchButtonVisible
                      />
                    </Form>
                  </Box>
                  {commonSearches.length > 0 && (
                    <Flex
                      mt={[7, 13]}
                      pb={[0, 3]}
                      flexDirection={['column', 'row']}
                      alignItems="center"
                      justifyContent="center"
                      width={[1, 2 / 3]}
                    >
                      <Flex pb={7} width="auto" flex="none">
                        <Text
                          fontWeight={2}
                          data-testId="roamingRatesCommonSearches"
                        >
                          {commonSearchesTitle}
                        </Text>
                      </Flex>
                      <Flex
                        ml={3}
                        flexDirection={['column', 'row']}
                        flexWrap="wrap"
                      >
                        {commonSearches.map(countryName => {
                          const {
                            fields: { name: commonCountryName },
                          } = countryName;
                          return (
                            <Box
                              p={0}
                              ml={[0, 7]}
                              mb={7}
                              sx={{
                                cursor: 'pointer',
                                backgroundColor: 'transparent',
                              }}
                              textAlign="center"
                              as={WclButton}
                              variant="none"
                            >
                              <Text
                                as="span"
                                fontSize={3}
                                key={commonCountryName}
                                fontWeight={2}
                                color={colors.blue}
                                onClick={() => {
                                  search(commonCountryName);
                                  setFieldValue('country', commonCountryName);
                                }}
                                setFieldValue={setFieldValue}
                                data-testId="roamingRatesCommonSearchesCountry"
                              >
                                {commonCountryName}
                              </Text>
                            </Box>
                          );
                        })}
                      </Flex>
                    </Flex>
                  )}
                </>
              )}
            />
            <TextBlock data={rightBlock} blockVariant={variant} />
          </Flex>
          {linkText && (
            <Box width={1} pt={[3, 9]} textAlign={['left', 'center']}>
              <Button
                data-testId="roaming-rates-open-popup-button"
                onClick={openPopup}
                {...analyticsSeeAllCountries}
              >
                <Text withAnimation fontWeight={2}>
                  {linkText}
                </Text>
              </Button>
            </Box>
          )}
        </Flex>
      </RestrictContent>
      {country && (
        <>
          <Box width={1} bg="softWhite" pt={17} pb={7}>
            <RestrictContent>
              <Box width={1} textAlign={['left', 'center']} px={[9, 0]}>
                <Heading fontSize={[7, 9]} lineHeight={[5, 7]}>
                  {`${name} ${roamingRatesText}`}
                </Heading>
              </Box>
              <Box
                width={1}
                px={[9, 21]}
                mt={13}
                textAlign={['left', 'center']}
              >
                {roamingRatesDescription && (
                  <Box mt={3}>
                    <TextBlock data={roamingRatesDescription} />
                  </Box>
                )}
                {ratesPlanDisclaimer && (
                  <Box pt={3}>
                    <TextBlock data={ratesPlanDisclaimer} />
                  </Box>
                )}
              </Box>
              {addOnsCountry && (
                <Box width={1} mt={17} px={9}>
                  <AddonCards
                    addOns={addOnsCountry}
                    identifier="ratesWithAddons"
                  />
                </Box>
              )}
            </RestrictContent>
          </Box>
          <RestrictContent>
            <Flex
              width={1}
              pt={[15, 21]}
              pb={21}
              px={[9, 0]}
              justifyContent="center"
            >
              <Box width={[1 / 2, 1 / 4]}>
                <Flex pb={4} minHeight="60px" alignItems="center">
                  <Text
                    lineHeight={[3, 5]}
                    fontWeight={1}
                    color="freedomCharcoal"
                  >
                    {standardRatesText}
                  </Text>
                </Flex>
                <Divider thickness={1} color="pureBlack" />
                <>
                  <RateBlock
                    rateType={dataText}
                    rate={standardDataRate}
                    pt={[7, 11]}
                    pb={[7, 11]}
                    renderAsRow={!worldTravellerRatesText}
                  />
                  <RateBlock
                    rateType={talkText}
                    rate={standardTalkRate}
                    pt={0}
                    pb={[7, 11]}
                    renderAsRow={!worldTravellerRatesText}
                  />
                  <RateBlock
                    rateType={textText}
                    rate={standardTextRate}
                    pt={0}
                    pb={0}
                    renderAsRow={!worldTravellerRatesText}
                  />
                </>
              </Box>
              {worldTravellerRatesText ? (
                <Box width={[1 / 2, 1 / 4]} ml={[11, 13]}>
                  <Flex pb={4} minHeight="60px" alignItems="center">
                    <Text
                      lineHeight={[3, 5]}
                      fontWeight={1}
                      color="freedomCharcoal"
                    >
                      {worldTravellerRatesText}
                    </Text>
                  </Flex>
                  <Divider thickness={1} color="pureBlack" />
                  <>
                    <RateBlock
                      rateType={dataText}
                      rate={worldSaverDataRate}
                      pt={[7, 11]}
                      pb={[7, 11]}
                    />
                    <RateBlock
                      rateType={talkText}
                      rate={worldSaverTalkRate}
                      pt={0}
                      pb={[7, 11]}
                    />
                    <RateBlock
                      rateType={textText}
                      rate={worldSaverTextRate}
                      pt={0}
                      pb={0}
                    />
                  </>
                </Box>
              ) : null}
            </Flex>
          </RestrictContent>
        </>
      )}
    </>
  );
};

RoamingRatesWithAddOns.propTypes = {
  country: PropTypes.string,
  title: PropTypes.string.isRequired,
  leftBlock: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  variant: PropTypes.string,
  validateField: PropTypes.func.isRequired,
  clearForm: PropTypes.func.isRequired,
  entryTitle: PropTypes.string.isRequired,
  entryKey: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  errorMessage: PropTypes.string,
  search: PropTypes.func.isRequired,
  rightBlock: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  commonSearchesTitle: PropTypes.string,
  commonSearches: PropTypes.arrayOf(PropTypes.object),
  openPopup: PropTypes.func.isRequired,
  analyticsSeeAllCountries: PropTypes.object.isRequired,
  linkText: PropTypes.string,
  roamingRatesText: PropTypes.string.isRequired,
  standardRatesText: PropTypes.string.isRequired,
  worldTravellerRatesText: PropTypes.string.isRequired,
  dataText: PropTypes.string.isRequired,
  talkText: PropTypes.string.isRequired,
  textText: PropTypes.string.isRequired,
  ratesPlanDisclaimer: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
  }),
  selectedCountry: PropTypes.object,
  addOnsCountry: PropTypes.arrayOf(PropTypes.object),
};

export default RoamingRatesWithAddOns;
