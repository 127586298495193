import styled from 'styled-components';
import { Button as BaseButton } from 'rebass';
import { Flex, Box, Card as BaseCard } from 'rebass/styled-components';

export const CardBlock = styled(Flex)`
  display: flex;
  justify-content: space-between;
  padding-bottom: 9.25em;
`;

export const Button = styled(BaseButton)`
  background: none;
  padding: 0;
  cursor: pointer;
  padding-left: 0;
`;

export const Footer = styled(Box)`
  position: absolute;
  bottom: 1px;
  display: inline-block;
  width: 100%;
`;
export const Card = styled(BaseCard)`
  position: relative;
`;
